<template>
    <div class="page">
        <img :src="require('@/assets/home/wj_pic_tjcg.png')" alt="">
        <div class="tit">自评提交成功</div>
        <div class="tips">您的自评已提交成功，感谢您的参与</div>
        <div class="btn"><van-button style="width: 100%;border-radius: 10px;" type="info"
                @click="back">我知道了</van-button></div>
    </div>
</template>
<script>
export default {
    data() {
        return {

        }
    },
    mounted() {

    },
    methods: {
        back() {
            this.$router.push('/check/index?type=0')
        }
    },
}
</script>
<style lang="less" scoped>
.page {
    background: #fff;
    height: 100vh;
    padding-top: 30%;
    box-sizing: border-box;
}

img {
    width: 123px;
    height: 131px;
    display: block;
    margin: 0 auto 0;
}

.tit {
    font-weight: bold;
    text-align: center;
    margin: 20px auto 5px;
}

.tips {
    width: 70%;
    text-align: center;
    margin: 20px auto 5px;
}

.btn {
    width: 70%;
    margin: 60px auto 0;
}
</style>